<template>
  <v-app id="main" style="background-color: black">
    <v-main style="background-color: transparent">
      <v-dialog
        v-model="showWert"
        transition="none"
        persistent
        style="overflow: hidden !important; height: 100vh !important; background-color: #1D2144; !important;"
        fullscreen
        ><div
          style="padding-top: 70px; height: 100vh; width: 100%"
          id="wert-widget"
        ></div
      ></v-dialog>
      <div class="glassHeader">
        <img
          style="max-width: 46px; height: auto; margin-left: 10px"
          src="./assets/ts-logo.png"
        />
        <div
          style="
            margin-right: 10px;
            display: flex;
            flex-grow: 1;
            justify-content: end;
          "
        >
          <div
            style="
              color: white;
              font-size: 8px;
              margin-top: 9px;
              margin-right: 10px;
            "
            v-show="showWert"
          >
            {{ activeAccount }}
          </div>
          <v-btn
            v-show="showWert"
            @click="showWert = false"
            color="red darken-3"
            style="margin-right: 10px; color: white"
            elevation="1"
            small
          >
            <v-icon size="18px" style="margin-right: 2px">
              {{ closeIcon }} </v-icon
            >Cerrar</v-btn
          >
          <v-btn
            v-show="!showWert"
            @click="buyTez()"
            color="primary darken-3"
            style="margin-right: 10px"
            elevation="1"
            small
            alt="Comprar Tezos"
          >
            <v-icon size="18px" style="margin-right: 5px"> {{ ccIcon }} </v-icon
            >Comprar XTZ</v-btn
          >
          <v-btn
            v-if="freeSpace > 0"
            v-show="!showWert"
            @click="delegate"
            color="primary darken-3"
            elevation="1"
            small
            ><v-icon size="18px" style="margin-right: 5px">
              {{ financeIcon }} </v-icon
            >Delegar</v-btn
          >
        </div>
      </div>
      <div
        style="
          color: white;
          display: flex;
          width: 100%;
          flex-grow: 1;
          flex-direction: column;
          padding-top: 80px;
          align-content: center;
          align-items: center;
          border: 0px solid yellow;
          height: 100%;
        "
        class="text-center"
      >
        <div
          style="
            width: 100%;
            max-width: 900px;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: row;
            display: flex;
            color: white;
            margin-top: 10px;
            padding: 20px;
            padding-top: 0px;
            border: 0px solid blue;
          "
        >
          <div style="display: flex; max-width: 560px; margin: 10px; margin-top: 0px; border: 0px solid yellow">
            <div style="text-align: left">
              <h1 class="display-3" style="text-align: left !important">
                Tezos España
              </h1>
              <p
                class="subtitle-2"
                style="color: #d8d4d5; margin: 4px; margin-top: 10px; text-align: left !important"
              >
                Baker de la red
                <a href="https://tezos.com" target="_blank">Tezos</a> con servicio de delegación y staking de tez en España
                alojado en servidores propios con las comisiones más justas y
                servicio automático de pagos.
                Validando y generando bloques de la red de Tezos desde febrero de 2022. 
              </p>
              <v-btn
                :disabled="freeSpace <= 0"
                @click="delegate"
                color="primary darken-3"
                raised
                large
                elevation="4"
                style="margin-top: 8px"
              >
                <span v-if="freeSpace > 0">Delegue a Tezos España</span>
                <span v-else>Sin Espacio Disponible</span>
              </v-btn>            
              <div style="font-size: 10px; margin-top: 5px; color: gray">
                <strong>Min Delegation:</strong> 100 XTZ,
                <strong>Delefation Fee:</strong> 4.75%,
                <strong>Estimated ROI:</strong> 5.13%
              </div>     
              <div style="font-size: 10px; margin-top: 0px; color: gray">
                <strong>Minimum Staking:</strong> 0 XTZ,
                <strong>Staking Fee:</strong> 3.5%               
              </div>         
              <div style="font-size: 10px; margin-top: 5px; color: gray">
                <strong>Baker Address:</strong> {{ baker_address }}
              </div>
              <div style="font-size: 10px; margin-top: 0px; color: gray">
                <strong>Payouts Address:</strong> {{ payouts_address }}
              </div>
            </div>
          </div>

          <div
            class="d-sm-flex d-md-flex"
            style="                                      
              max-width: 280px;
              display: flex;
              justify-content: center;
              border: 0px solid green;
              text-align: center;
            "
          >
          <div style="margin-top: 20px;">
            <img
              style="max-width: 250px; height: auto"
              src="./assets/bakers.gif"
            />
            <p style="font-size: 8pt; color: darkgray; margin-left: 10px; margin-right: 10px;">Ganadores del <a href="https://x.com/tezos/status/1829571888303227205" target="_blank">Tezos Staking Contest</a> en el ciclo <strong>772</strong> y orgullos propietarios del NFT <a href="https://objkt.com/tokens/hicetnunc/856318" target="_blank">BAKERZ02</a> de <strong>Gengoya</strong></p>
          </div>
          </div>
        </div>
        <div
          class="glass"
          style="
            margin: 10px;
            margin-top: 10px;
            max-width: 900px;
            height: auto;
            justify-items: center;
            align-items: center;
            align-content: center;
            justify-content: center;
            width: 90%;
            display: flex;
            flex-direction: column;
            padding: 0px;
            margin-bottom: 10px;
          "
        >
        <h4 class="header" style="margin: 10px; margin-bottom: 0px">
      Baker Tezos España
    </h4>
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              margin: 0px;
              margin-top: 0px;
            "
          >
            <v-col style="min-width: 120px">
              <h4 class="header2">Actividad</h4>
              <h5 class="stat">{{ getLastActivity() }}</h5>
            </v-col>
            <v-col style="min-width: 120px">
              <h4 class="header2">Aprobaciones</h4>
              <Transition name="fade">
                <h5 class="stat" v-if="fadeUpdateEndorsements">
                  {{ getEndorsements() }}
                </h5>
              </Transition>
            </v-col>
            <v-col style="min-width: 120px">
              <h4 class="header2">Bloques</h4>
              <Transition name="fade">
                <h5 class="stat" v-if="fadeUpdateBlocks">
                  {{ getBlocks() }}
                </h5>
              </Transition>
            </v-col>
            <v-col style="min-width: 120px">
              <h4 class="header2">Stake</h4>
              <h5 class="stat">{{ getBalance() }} XTZ</h5>
              <div
                style="
                  margin-top: 4px;
                  text-align: center;
                  display: flex;
                  justify-content: center;
                "
              >
                <v-progress-linear
                  color="primary darken-2"
                  style="max-width: 90px"
                  :value="stakePct"
                ></v-progress-linear>
              </div>
            </v-col>
          </div>
        </div>

        <MemPool class="d-none d-md-block" />

        <div
          style="
            margin: 0px;
            margin-top: 0px;
            max-width: 900px;
            height: auto;
            justify-items: center;
            align-items: center;
            align-content: center;
            justify-content: center;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 10px;
            margin-bottom: 0px;
          "
        >
          <div
            style="display: flex; flex-wrap: wrap; width: 100%; margin-top: 0px"
          >
            <v-col
              class=""
              style="min-width: 300px; margin-right: 10px; padding: 20px"
            >
              <h4 class="header1">
                <svg-icon
                  style="margin-right: 10px"
                  type="mdi"
                  :path="serverIcon"
                ></svg-icon
                >Servidores Propios
              </h4>
              <p
                class="stat"
                style="
                  text-align: justify;
                  text-justify: inter-word;
                  margin-top: 10px;
                "
              >
                De acuerdo con TzKT
                <a href="https://tzkt.io/stats" target="_blank"
                  >más del 90% de los nodos de Tezos están alojados en la
                  nube</a
                >. En Tezos España nuestro baker y nodos están alojados en
                <strong>servidores propios</strong> en Madrid ayudando así a la
                verdadera descentralización de la red y su estabilidad.
                Aseguramos su estabilidad mediante redundancia y fuentes de
                alimentación con respaldo de baterías.
              </p>
            </v-col>

            <!-- <v-col
              class=""
              style="min-width: 300px; margin-right: 10px; padding: 20px"
            >
              <h4 class="header1">
                <svg-icon
                  style="margin-right: 10px"
                  type="mdi"
                  :path="shieldIcon"
                ></svg-icon
                >Recompensas&nbsp;<span
                  style="text-decoration: underline; font-weight: bold"
                  >Aseguradas</span
                >
              </h4>
              <p
                class="stat"
                style="
                  text-align: justify;
                  text-justify: inter-word;
                  margin-top: 10px;
                "
              >
                Las recompensas por su staking en Tezos España están aseguradas
                por el
                <a href="https://sure.baking-bad.org/#insured" target="_blank"
                  >programa de seguros de Baking Bad</a
                >
                conocidos por su sistema de auditoría y rating de bakers en
                Tezos. Tezos España ha realizado un depósito que será usado por
                Baking Bad para pagar las recompensas de manera íntegra en caso
                de que nuestro baker sufriera algún problema.
              </p>
            </v-col>
            -->

            <v-col
              class=""
              style="min-width: 300px; margin-right: 10px; padding: 20px"
            >
              <h4 class="header1">
                <svg-icon
                  style="margin-right: 10px"
                  type="mdi"
                  :path="noSellIcon"
                ></svg-icon
                >No Vendemos
              </h4>
              <p
                class="stat"
                style="
                  text-align: justify;
                  text-justify: inter-word;
                  margin-top: 10px;
                "
              >
                En Tezos España creemos en la red Tezos y desde el registro de
                nuestro baker en febrero de 2022
                <strong>no hemos vendido ni un solo tez.</strong> Nuestro
                compromiso es hacer crecer nuestro baker manteniendo las
                recompensas por endorsement y bloques de manera íntegra hasta
                que Tezos alcance la popularidad y reconocimiento que se merece.
              </p>
            </v-col>

            <v-col
              class=""
              style="min-width: 300px; margin-right: 10px; padding: 20px"
            >
              <h4 class="header1">
                <svg-icon
                  style="margin-right: 10px"
                  type="mdi"
                  :path="voteIcon"
                ></svg-icon
                >Participación
              </h4>
              <p
                class="stat"
                style="
                  text-align: justify;
                  text-justify: inter-word;
                  margin-top: 10px;
                "
              >
                En Tezos España participamos activamente en el sistema de
                gobernanza <i>on-chain</i> de Tezos emitiendo voto para todas
                las propuestas de mejora y actualización elegidas por la
                comunidad contribuyendo así a que Tezos siga siendo una
                blockchain dinámica, innovadora y con la última tecnología.
              </p>
            </v-col>

            <v-col
              class=""
              style="min-width: 300px; margin-right: 10px; padding: 20px"
            >
              <h4 class="header1">
                <svg-icon
                  style="margin-right: 10px"
                  type="mdi"
                  :path="anonIcon"
                ></svg-icon
                >Privacidad
              </h4>
              <p
                class="stat"
                style="
                  text-align: justify;
                  text-justify: inter-word;
                  margin-top: 10px;
                "
              >
                No es necesario ningún tipo de registro con nosotros para
                delegar su XTZ a nuestro baker. Respetamos su privacidad y no
                recogemos ninguna información personal. Puede delegar sus tez
                con nosotros pulsando el botón de la parte superior o hacerlo
                directamente desde su cartera u otros proveedores.
              </p>
            </v-col>

            <v-col
              class=""
              style="min-width: 300px; margin-right: 10px; padding: 20px"
            >
              <h4 class="header1">
                <svg-icon
                  style="margin-right: 10px"
                  type="mdi"
                  :path="cashClockIcon"
                ></svg-icon
                >Pago Automático
              </h4>
              <p
                class="stat"
                style="
                  text-align: justify;
                  text-justify: inter-word;
                  margin-top: 10px;                 
                "
              >
                Usamos el generador automático de recompensas
                <i>Tezos Rewards Distributor</i> (TRD) para generar los pagos
                automáticamente al final de cada ciclo (aproximadamente 3 días)
                por lo que usted no tendrá que hacer nada y las recompensas
                llegarán directamente a su cuenta de Tezos desde la que haya
                delegado con nosotros.
              </p>
            </v-col>
          </div>
        </div>

        <div style="max-width: 840px; margin: 30px; margin-bottom: 30px; display: flex; flex-direction: column; justify-content: center;">
          <h4 class="header1">
            <svg-icon
              style="margin-right: 10px"
              type="mdi"
              :path="developIcon"
            ></svg-icon
            >Proyectos
          </h4>
          <div style="width: 100%; text-align: center;"><p
            class="stat"
            style="
              text-align: center;
              text-justify: inter-word;
              text-align: justify;
              margin-top: 10px;   
              margin-bottom: 30px;         
            "
          >
            En Tezos España colaboramos de manera activa en el desarrollo y la expansión del ecosistema Tezos
            mediante la creación de diversos proyectos y colecciones. Delegando sus tez con nosotros nos ayuda a seguir participando y dedicando esfuerzos a la integración de proyectos y a dar a conocer la red Tezos de manera global.
          </p></div>
          <div
            style="
              display: flex;
              justify-content: center;            
              flex-wrap: wrap;
              margin-top: 20px;
            "
          >
          <div
              style="
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <div>
                <img
                  style="max-width: 92px; height: auto; margin-left: 10px"
                  src="./assets/taezars.jpeg"
                />
              </div>
              <div><a class="project" href="https://taezars.com" target="_blank">Taezars</a></div>
            </div>
            <div
              style="
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <div>
                <img
                  style="max-width: 92px; height: auto; margin-left: 10px"
                  src="./assets/numitzmatics.png"
                />
              </div>
              <div><a class="project" href="https://numitzmatics.com" target="_blank">Numitzmatics</a></div>
            </div>
            <div
              style="
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <div>
                <img
                  style="max-width: 92px; height: auto; margin-left: 10px"
                  src="./assets/taezarspfps.jpeg"
                />
              </div>
              <div><a class="project" href="https://objkt.com/collection/KT1WZjZqzby4svYebE5NwGZDP6KrvupRKmw6" target="_blank">Taezars PFPs</a></div>
            </div>
            <!--
            <div
              style="
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <div>
                <img
                  style="max-width: 92px; height: auto; margin-left: 10px"
                  src="./assets/ts-logo.png"
                />
              </div>
              <div>Monitor de Transacciones</div>
            </div>
          -->
          </div>
        </div>
        <div
          no-gutters
          style="text-align: center; width: 80%; margin-top: 10px"
        >
          <v-btn
            :disabled="freeSpace <= 0"
            @click="delegate"
            color="primary darken-3"
            raised
            x-large
            elevation="4"
          >
            <span v-if="freeSpace > 0">Delegue a Tezos España</span>
            <span v-else>Sin Espacio Disponible</span>
          </v-btn>
          <div style="font-size: 10px; margin-top: 5px; color: gray">
            <strong>Mínimo:</strong> 100 XTZ, <strong>Comisión:</strong> 4.75%,
            <strong>ROI Estimado:</strong> 5.13%
          </div>
          <div style="font-size: 10px; margin-top: 0px; color: gray">
            <strong>Baker Address:</strong> {{ baker_address }}
          </div>
          <div style="font-size: 10px; margin-top: 0px; color: gray">
            <strong>Payouts Address:</strong> {{ payouts_address }}
          </div>
        </div>
        <div style="color: #d2d4d5; margin-top: 20px">
          <!--<script
            async
            type="application/javascript"            
            src="https://widgets.baking-bad.org/insurance-widget.js"
            data-baker-address="tz1eu3mkvEjzPgGoRMuKY7EHHtSwz88VxS31"
            data-rounded-widget="true"
          ></script>          -->
        </div>

        <div
          style="margin-top: 0px; margin-bottom: 45px; padding: 20px"
          v-if="lastVote"
        >
          <v-banner dark outlined rounded raised color="secondary">
            {{ lastVote }}</v-banner
          >
        </div>
      </div>
    </v-main>
    <v-footer padless style="border: 0px">
      <v-card
        flat
        tile
        class="white--text text-center glass"
        style="
          width: 100%;
          border: 0px !important;
          border-top: 1px;
          padding: 0px;
          background-color: #021b3c;
        "
      >
        <v-card-text>
          <v-btn
            href="https://twitter.com/tezos_es"
            target="_blank"
            class="mx-2 white--text"
            icon
          >
            <v-icon size="24px">
              {{ twitterIcon }}
            </v-icon>
          </v-btn>
          <v-btn
            href="mailto:baker@tezos.es"
            target="_blank"
            class="mx-2 white--text"
            icon
          >
            <v-icon size="24px">
              {{ mailIcon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Tezos España</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<style scoped>
body {
  background-color: black;
}
#main {
  background-image: url("./assets/static.svg");
  background-size: cover;
}

.glassHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 70px;
  padding: 4px;
  display: flex;
  align-content: center;
  align-items: center;
  /* From https://css.glass */
  background: rgba(3, 19, 52, 0.47);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
}
.glass {
  /* From https://css.glass */
  background: rgba(6, 1, 28, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(6, 1, 28, 0.3);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.stat {
  color: #d8d4d5;
  font-size: 14px;
}

.header {
  color: #02e4e5;
  font-size: 16px;
  text-decoration: none;
}

.header1 {
  color: white;
  font-size: 20px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.header2 {
  color: #02e4e5;
  font-size: 12px;
  border: 0px solid yellow;
  padding: 4px;
  font-weight: bold;
}

.project {
  text-decoration: none;
  color: rgb(190, 190, 190);
}

.project:hover {
  opacity: 80%;
}
</style>

<script>
import MemPool from "./components/MemPool.vue";
import { DAppClient, TezosOperationType } from "@airgap/beacon-sdk";
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiEmailOutline,
  mdiCreditCard,
  mdiFinance,
  mdiPercentCircleOutline,
  mdiCreditCardOutline,
  mdiTwitter,
  mdiServer,
  mdiPercentBoxOutline,
  mdiCurrencyUsdOff,
  mdiVote,
  mdiShieldCheckOutline,
  mdiCashClock,
  mdiIncognito,
  mdiCancel,
  mdiClose,
  mdiRulerSquareCompass,
} from "@mdi/js";
import numeral from "numeral";
import moment from "moment";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
TimeAgo.addDefaultLocale(es);

export default {
  name: "App",

  components: {
    SvgIcon,
    MemPool,
  },
  data: () => ({
    serverIcon: mdiServer,
    percentIcon: mdiPercentBoxOutline,
    noSellIcon: mdiCurrencyUsdOff,
    voteIcon: mdiVote,
    shieldIcon: mdiShieldCheckOutline,
    cashClockIcon: mdiCashClock,
    anonIcon: mdiIncognito,
    twitterIcon: mdiTwitter,
    mailIcon: mdiEmailOutline,
    ccIconOutline: mdiCreditCardOutline,
    ccIcon: mdiCreditCard,
    percentIconOutline: mdiPercentCircleOutline,
    financeIcon: mdiFinance,
    cancelIcon: mdiCancel,
    closeIcon: mdiClose,
    developIcon: mdiRulerSquareCompass,
    baker_address: "tz1eu3mkvEjzPgGoRMuKY7EHHtSwz88VxS31",
    payouts_address: "tz1bU26pkp3Eg6T4vqDwrMoqLyrTaXevqrZA",
    stats: {},
    fadeUpdateEndorsements: false,
    fadeUpdateBlocks: false,
    endorsements: 0,
    blocks: 0,
    wallet: undefined,
    activeAccount: undefined,
    lastVote: undefined,
    showWert: false,
    wertWidget: undefined,
    client: undefined,
    freeSpace: 0,
    stakePct: 0
  }),
  created() {
    this.getPeriods();
    this.getData();
    setInterval(() => {
      this.getData();
    }, 15000);
  },
  mounted() {
    this.client = new DAppClient({
      name: "Tezos España",
    });
  },
  methods: {
    createWert() {
      //console.log(this.activeAccount);
      this.wertWidget = new window.WertWidget({
        partner_id: "01GF0KN9VTDZJ6TNT5WSY2RHZX",
        container_id: "wert-widget",
        origin: "https://widget.wert.io",
        theme: "dark",
        commodities: "XTZ:Tezos",
        lang: "en",
        address: this.activeAccount ? this.activeAccount : "",
        color_background: "#1d2144",
      });

      this.showWert = true;
      this.$nextTick(() => {
        this.wertWidget.mount();
      });
    },
    buyTez() {
      this.client.clearActiveAccount();

      this.client.getActiveAccount().then((activeAccount) => {
        if (activeAccount) {
          this.activeAccount = activeAccount.address;
          this.createWert();
        } else {
          // If we don't have an active account, we need to request permissions first and then send the delegate operation
          this.client.requestPermissions().then(() => {
            this.client.getActiveAccount().then((activeAccount) => {
              this.activeAccount = activeAccount.address;
              this.createWert();
            });
          });
        }
      });
    },
    getBalance() {
      return numeral(this.stats.stakingBalance / 1e6).format("0,0");
    },
    getStakingCapacity() {
      let balance = this.stats.balance / 1e6;
      let capacity = 10 * balance;
      let currentStake = this.stats.stakingBalance / 1e6;
      this.freeSpace = capacity - currentStake;
      this.stakePct = Math.round((100 * currentStake) / capacity);
      return numeral(this.freeSpace).format("0,0"); // + "/" + numeral(capacity).format("0,0")
    },
    getEndorsements() {
      return numeral(this.endorsements).format("0,0");
    },
    getBlocks() {
      return numeral(this.blocks).format("0,0");
    },
    getLastActivity() {
      let timeAgo = new TimeAgo("es");
      return timeAgo.format(
        new moment(this.stats.lastActivityTime).toDate(),
        "round-minute"
      );
    },
    async delegate() {
      this.client.clearActiveAccount();

      const delegate = () => {
        this.client.requestOperation({
          operationDetails: [
            {
              kind: TezosOperationType.DELEGATION,
              delegate: this.baker_address,
            },
          ],
        });
      };

      this.client.getActiveAccount().then((activeAccount) => {
        if (activeAccount) {
          this.activeAccount = activeAccount.address;
          // If we have an active account, send the delegate operation directly
          delegate();
        } else {
          // If we don't have an active account, we need to request permissions first and then send the delegate operation
          this.client.requestPermissions().then(() => {
            this.client.getActiveAccount().then((activeAccount) => {
              this.activeAccount = activeAccount.address;
              delegate();
            });
          });
        }
      });
    },
    getData() {
      fetch("https://api.tzkt.io/v1/accounts/" + this.baker_address)
        .then((response) => response.json())
        .then((data) => {
          if (data.numBlocks != this.blocks) {
            this.fadeUpdateBlocks = false;
            this.$nextTick(() => {
              this.fadeUpdateBlocks = true;
            });
          }
          if (data.numEndorsements != this.endorsements) {
            this.fadeUpdateEndorsements = false;
            this.$nextTick(() => {
              this.fadeUpdateEndorsements = true;
            });
          }

          this.blocks = data.numBlocks;
          this.endorsements = data.numEndorsements;

          //console.log(data);
          this.stats = data;
          this.getStakingCapacity();
        });
    },
    async getPeriods() {
      fetch("https://api.tzkt.io/v1/voting/epochs/latest_voting")
        .then((response) => response.json())
        .then((data) => {
          let index = {};
          data.periods.forEach((p) => {
            if (p.kind == "exploration") {
              index = p;
            } else if (p.kind == "promotion") {
              index = p;
            }
          });
          let proposal = undefined;
          data.proposals.forEach((p) => {
            if (p.status == "active") {
              proposal = p.metadata.alias;
            }
          });
          this.getVote(index, proposal);
        });
    },
    async getVote(p, proposal) {
      fetch(
        "https://api.tzkt.io/v1/voting/periods/" +
          p.index +
          "/voters/" +
          this.baker_address
      )
        .then((response) => response.json())
        .then((data) => {
          //console.log(proposal);
          //console.log(p);
          //console.log(data);
          let t = "didn't vote";
          if (data.status == "voted_yay") {
            t = "voted YAY";
          } else if (data.status == "voted_nay") {
            t = "voted NAY";
          } else if (data.status == "voted_pass") {
            t = "voted PASS";
          } else if (data.status == "upvoted") {
            t = "UPVOTED";
          } else if (data.status == "none") {
            t = "DID NOT VOTE";
          }
          if (proposal) {
            this.lastVote =
              "Tezos España " +
              t +
              " in " +
              p.kind +
              " phase of " +
              proposal +
              " proposal";
          }
          //console.log(this.lastVote);
        });
    },
  },
};
</script>
