<template>
  <div
    class="glass"
    style="
      margin: 10px;
      margin-top: 10px;
      max-width: 900px;
      justify-items: center;
      align-items: center;
      align-content: center;
      justify-content: center;
      width: 90%;
      display: flex;
      flex-direction: column;
      padding: 0px;
      margin-bottom: 10px;
    "
  >
    <h4 class="header" style="margin: 10px; margin-bottom: 0px">
      Monitor de Transacciones
    </h4>
    <div
      style="
        display: flex;
        justify-content: center;

        padding: 10px;
        padding-bottom: 0px;
      "
    >
      <div
        class="header1 d-none d-md-block"
        style="width: 75px; text-align: center"
      >
        Hora
      </div>
      <div
        class="header1 d-none d-md-block"
        style="width: 70px; text-align: right"
      >
        Bloque
      </div>
      <div class="header1" style="width: 250px; text-align: left">Origen</div>
      <div class="header1" style="width: 250px; text-align: left">Destino</div>
      <div
        class="header1 d-none d-md-block"
        style="width: 140px; text-align: left"
      >
        Método
      </div>
      <div
        class="header1 d-none d-md-block"
        style="width: 90px; text-align: right"
      >
        Cantidad
      </div>
    </div>
    <div
      style="
        height: 300px;
        padding: 10px;
        padding-top: 0px;
        margin-bottom: 10px;
        overflow-y: auto;
        width: 100%;
      "
    >
      <div
        class="dataRow"
        :class="{ pending: t.block == -1 }"
        v-for="t in mempool.slice(-this.SLICE).reverse()"
        :key="t.opHash+'-'+t.counter"
        style="display: flex; justify-content: center; padding: 0px"
      >
        <div
          class="stat d-none d-md-block"
          style="width: 75px; text-align: center"
        >
          {{ new Date(t.timestamp).toLocaleTimeString() }}
        </div>
        <div
          class="stat d-none d-md-block"
          style="width: 70px; text-align: right"
        >
          {{ t.block > 0 ? t.block : "" }}
        </div>
        <div class="stat" style="width: 250px; text-align: left">
          {{ (t.senderAlias) }}
        </div>
        <div class="stat" style="width: 250px; text-align: left">
          {{ (t.targetAlias) }}
        </div>
        <div
          class="stat d-none d-md-block"
          style="width: 140px; text-align: left"
        >
          {{ t.entrypoint ? t.entrypoint : "-" }}
        </div>
        <div
          class="stat d-none d-md-block"
          style="width: 90px; text-align: right"
        >
          {{ t.amount }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.glass {
  /* From https://css.glass */
  background: rgba(6, 1, 28, 0.2);
  border-radius: 0px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(6, 1, 28, 0.3);
}

.header {
  color: #02e4e5;
  font-size: 16px;
  border: 0px solid yellow;
  padding: 4px;
  text-decoration: none;
}

.header1 {
  color: #02e4e5;
  font-size: 12px;
  border: 0px solid yellow;
  padding: 4px;
  font-weight: bold;
}
.stat {
  /*color: #e3e1e1;*/

  font-size: 10px;
  border: 0px solid rgb(23, 23, 23);
  padding: 2px;
  font-family: "Roboto Mono";
}

.pending {
  font-style: italic;
  color: #ababab !important;
}

.dataRow {
  color: #f5f5f5;
  border-bottom: 1px dashed rgb(59, 59, 59);
}
</style>

<script>
export default {
  name: "MemPool",
  created() {
    this.initWS();
  },
  data: () => ({
    mempool: [],
    SLICE: 500,
  }),
  methods: {
    shortenAddress(address, startLength = 6, endLength = 5) {
      if (address.length < 30) {
        return address;
    }
    if (address.length <= startLength + endLength) {
        return address;
    }
    const start = address.substring(0, startLength);
    const end = address.substring(address.length - endLength);
    return `${start}...${end}`;
},
    initWS() {
      //console.log("Starting connection to WebSocket Server");
      this.connection = new WebSocket("wss://mad.delphin.io:7777");

      this.connection.onmessage = (event) => {
        let msg = JSON.parse(event.data);
        if (msg.type == "init") {
          //console.log("Recibidas transacciones iniciales");
          //console.log(msg.data);
          msg.data.forEach((element) => {
            if (element.opHash) this.mempool.push(element);
          });
        } else if (msg.type == "mempool") {
          //console.log("Recibida transacción en mempool");
          this.mempool.push(msg.data);
        } else if (msg.type == "block") {
          //console.log("Recibido bloque");
          /// Update existing mempool transactions with block level
          msg.data.forEach((m) => {
            let found = false;
            this.mempool.slice(-this.SLICE).forEach((a) => {
              if (m.opHash == a.opHash) {
                a.block = m.block;
                found = true;
              }
            });
            if (!found) this.mempool.push(msg.data);
          });
          //this.mempool.push(msg.data);
        }
      };

      this.connection.onclose = (e) => {
        console.log("Socket is closed. Reconnect will be attempted.", e.reason);
        setTimeout( () => {
          this.initWS();
        }, 500);
      };

      this.connection.onopen = function () {
        console.log("Conectado correctamente a Tezos España...");
      };
    },
  },
};
</script>
